<template>
    <div class="box">
        <el-card>
        <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="充值记录" name="1">
        <el-table :data="tableData" style="width: 100%" tooltip-effect="dark">
                <el-table-column align="center" width="100px" prop="user.id" label="用户ID"></el-table-column>
                <el-table-column align="center"  prop="user.username" label="用户名称"></el-table-column>
                 <el-table-column align="center" prop="title" label="标题" ></el-table-column>
               <el-table-column align="center"  label="充值类型" >
                    <template slot-scope="scope">
                       <span v-if="scope.row.pay_type==1">微信</span>
                       <span v-else>其他</span>
                   </template>
                </el-table-column>
                <el-table-column align="center" prop="fee" label="充值金额" ></el-table-column>      
                <el-table-column align="center" prop="out_trade_no" label="订单号" ></el-table-column>
                <el-table-column align="center" prop="pay_time" label="操作时间" ></el-table-column>
    </el-table>
        </el-tab-pane>
        <el-tab-pane label="提现记录" name="2">
            <el-table :data="tableData" style="width: 100%" tooltip-effect="dark">
                <el-table-column align="center" width="100px" prop="user_id" label="用户ID"></el-table-column>
                <el-table-column align="center"  prop="user.username" label="用户名称"></el-table-column>
                 <el-table-column align="center" prop="title" label="标题" ></el-table-column>
               <el-table-column align="center"  label="提现类型" >
                    <template slot-scope="scope">
                       <span v-if="scope.row.pay_type==1">微信</span>
                       <span v-else>其他</span>
                   </template>
                </el-table-column>
                <el-table-column align="center" prop="fee" label="提现金额" ></el-table-column>      
                <el-table-column align="center" prop="out_trade_no" label="订单号" ></el-table-column>
                <el-table-column align="center" prop="create_time" label="操作时间" ></el-table-column>
                <el-table-column align="center"  label="操作" >
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark"  content="机构提现操作" placement="top-start">
                        <el-button icon="el-icon-edit" type="danger" @click="fOperate(scope.row.user_id)" ></el-button>
                    </el-tooltip>
                   </template>
                </el-table-column>
    </el-table>
        </el-tab-pane>
    </el-tabs>
     <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
      </el-card>
          <!-- 审核提示框 -->
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <span>是否同意提现金额?</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="refuse">拒 绝</el-button>
    <el-button type="primary" @click="consent">同 意</el-button>
  </span>
</el-dialog>
    </div>
    
</template>

<script>
import {PostWithdraw,PostOperate} from "@/api/Firm/frecdep.js";
export default {
    name: 'frecdep',
    data() {
        return {
            //机构提现模态框
      dialogVisible:false,
      dialogFalimyID:"",
            activeName: '2',
            tableData: [],
           //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
        };
    },
    created(){
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num,
      type:this.activeName
    }
       this.PostWithdraw(data);
    },
    mounted() {
        
    },

    methods: {
          handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
        handleClick() {
        this.tableData=[]    
        var data={
            page:this.getInfo.page_code,
            list_num:this.getInfo.page_num,
            type:this.activeName
            }
       this.PostWithdraw(data);
      },
      //机构提现操作
      fOperate(id){
          console.log(id);
          this.dialogFalimyID=id
       this.dialogVisible=true
      },
      //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
       this.tableData=[]    
        var data={
            page:this.getInfo.page_code,
            list_num:this.getInfo.page_num,
            type:this.activeName
            }
       this.PostWithdraw(data);
    console.log(data);
    },
    //拒绝提现
      refuse(){
        var that=this;
        var data={
          id:this.dialogFalimyID,
          status:2
         }
          this.$confirm('确认拒绝？')
          .then(_ => {
                   let res=PostOperate(data);
                res.then((res)=>{
                     if(res.data.code==1){
            this.tableData=[]    
        var data={
            page:this.getInfo.page_code,
            list_num:this.getInfo.page_num,
            type:this.activeName
            }
       this.PostWithdraw(data);
              this.$message({
              message: res.data.message,
              type: 'success'
            });
         }else{
             this.$message.error(res.data.message);
         }
                }).catch((err)=>{
                    console.log(err);
                })
                 that.dialogVisible=false;
          })
          .catch(_ => {});
      },
      //同意提现
      consent(){
        var that=this;
         var data={
          id:this.dialogFalimyID,
          status:1
         }
           this.$confirm('确认注销？')
          .then(_ => {
                   let res=PostOperate(data);
                res.then((res)=>{
                     if(res.data.code==1){
           this.tableData=[]
                  var data={
              page:this.getInfo.page_code,
              list_num:this.getInfo.page_num
            }
            this.PostOperate(data);
              this.$message({
              message: res.data.message,
              type: 'success'
            });
         }else{
             this.$message.error(res.data.message);
         }
                }).catch((err)=>{
                    console.log(err);
                })
                 that.dialogVisible=false;
          })
          .catch(_ => {});
        
      },
      
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      this.tableData=[]    
        var data={
            page:this.getInfo.page_code,
            list_num:this.getInfo.page_num,
            type:this.activeName
            }
       this.PostWithdraw(data);
    console.log(data);
    },
     //获取充值提现列表网络请求
    async PostWithdraw(data){
        let res=await PostWithdraw(data);
        if(res.data.code==1){
          this.exp.num=res.data.data.total;
          this.tableData=this.tableData.concat(res.data.data.data);
              console.log(res.data)
        }else{
            this.$message.error(res.data.message);
        } 
    }
    },
};
</script>

<style  scoped>
.box{
    padding:10px 20px;
}
.el-table .cell .el-button{
  margin: 5px;
}
.page {
  margin-top: 20px;
}

</style>